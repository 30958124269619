import axios from 'axios';
import React,{ useEffect, useState } from 'react';
import styled from 'styled-components';
import useRedirect from '../functions/useRedirect'
import LOGO from '../assets/logo.png'
import { useStateForm } from '../elements/useStateForm';
import TextInput from '../elements/TextInput';
import Loading from '../elements/Loading';
import { colors } from '../config';

const ChangePasswordPage = ({match}:any) => {

    const {code} = match.params
    const [status,setStatus] = useState(0);

    const {registerField,getFields,setError} = useStateForm({},{
      password: {
        require: true,
        minLength: 6,
        min: 6,
      },
      password2: {
        require: true
      }
    });

    const [loading,setLoading] = useState(false);

    const submit = async () => {

       setLoading(true);
        try {
            const {password,password2} = getFields();

            if(password !== password2)
              setError("password2","Senhas divergentes")

             await axios.post("https://portal-api.entregakii.com.br/callback/changepassword",{
                password,code
             })
          //    await axios.post("http://localhost:3000/callback/changepassword",{
          //     password,code
          //  })
            setStatus(200)
        }
        catch (err:any){
          if(err.response)
            setStatus(err.response.status)
        }
        setLoading(false);
    }

    if(loading)
      return <Loading/>

    if(status === 200)
      return <Container>

      <div className="title">
        Sucesso
      </div>

      <div className="subtitle">
          Senha alterada com sucesso.
      </div>
    </Container> 

    else if(status === 400)
      return <Container>

      <div className="title" style={{color: colors.red400}}>
        Algo deu errado
      </div>

      <div className="subtitle">
          Não foi possível alterar a senha, tente novamente mais tarde.
      </div>

  </Container> 


else if(status === 404)
      return <Container>

      <div className="title" style={{color: colors.red400}}>
        Algo deu errado
      </div>

      <div className="subtitle">
          Este link para alterar senha expirou!
      </div>

  </Container> 
    
      return <Container>

          <div className="title">
            Alterar senha
          </div>

          <div className="subtitle">
              Insira abaixo sua nova senha e clique em confirmar!
          </div>

          <div>
            <div style={{height: 20}}/> 
            <TextInput max={32} password placeholder="Nova senha" {...registerField("password")}/>
            <div style={{height: 10}}/> 
            <TextInput max={32} password placeholder="Confirmar nova senha"  {...registerField("password2")}/> 
          </div>

          <button onClick={submit}>
            Confirmar
          </button>

          <div className="subtitle" style={{color: "#888"}}>
              Atenção suas sessões antigas serão encerradas!
          </div>

          {/* <div className="note">
              NOTE: In Chrome for Android, if the user writes the url manually, it assumes that he does <b>not</b> wish to be redirected outside of the browser. So you want to be redirected to this link or have clicked a link to this url.
          </div> */}
          {/* <a onClick={redirect}>Ir para o app</a> */}
          {/* </div> */}
        </Container>
  
}


export const Container = styled.div`

  padding: 50px;

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  

  .picture{
    width: 150px;
    height: 150px;
    background-color: #ddd;
    background-size: cover;
    border-radius: 75px;
  }

  .title{
    font-size: 32px;
    text-align: center;
    font-family: Whitney Bold;
    margin-top: 20px;
    max-width: 600px;
  }
  .subtitle{
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
    max-width: 600px;
    font-family: Whitney;
  }

  button {
 
    width: 100%;
    margin-top: 20px;
    height: 2.81rem;
    line-height: 2.8rem;
    border-radius: 7px;
    background-color: #2334FF;
    box-shadow: 0 4px 14px 0  #2334FF77;
    color: white;
    transition: background 0.2s ease, color 0.2s ease, box-shadow 0.2s ease;
    outline: none;
    cursor: pointer;
    border: none;
    max-width: 300px;
    font-size: inherit;
    font-family: Whitney Bold;
}
`;





export default ChangePasswordPage;
