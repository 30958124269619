import React from 'react';
import Stack from './routes';

function App() {
  return (
    <Stack/>
  );
}

export default App;
