import React from 'react';
import { Switch } from 'react-router-dom';
import { BrowserRouter, Route  } from 'react-router-dom';
import ChangePasswordPage from './pages/ChangePassword';
import HomePage from './pages/Home';
import RedirectPage from './pages/Redirect';

const Stack = () => {

  return <>
  <BrowserRouter>

        <Switch>

            <Route path="/" exact>
              <HomePage/>
            </Route>
            <Route path="/alterarsenha/:code" exact component={ChangePasswordPage}/>
            <Route path="*" >
              <RedirectPage/>
            </Route>
            
          </Switch>
                
    </BrowserRouter>
  </>


}

export default Stack;