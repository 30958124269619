import { createGlobalStyle } from 'styled-components'
import WHITNEYBOLD from './assets/fonts/whitney-bold.woff'
import WHITNEY from './assets/fonts/whitney.woff'


export default createGlobalStyle`


    @font-face {
        font-family: Whitney;
        src: url(${WHITNEY});
    }  
    @font-face {
        font-family: Whitney Bold;
        src: url(${WHITNEYBOLD});
    }     

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

  }

  view{
    display: flex;
    flex-direction: column;
  }

  a{
      text-decoration: none;
  }
  html, body, #root {
    max-height: 100vh;
    max-width: 100vw;
    width: 100%;
    height: 100%;
  }
  *, button, input {
    border: 0;
    background: none;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, Arial, sans-serif;
  }
  html {
    background: var(--primary);
  }



`;