const width = window.innerWidth
const height = window.innerHeight

export const colors = {
    //logo_color: "#00AA00",
    logo_color: "#2334FF",
   // primary_color: "#121212",
    primary_color: "#2334FF",
    link: "#004BFF",
    inverse_primary_color: "#fff",

    hightlight: "#ffa700",
    money: "#38B64A",

    red400: "#E11900",
    red300: "#e85c4a",
    red200: "#f1998e",
    red100: "#fed7d2",

    yellow400: "#FFC043",
    yellow300: "#FFCf70",
    yellow200: "#ffe3ac",
    yellow100: "#fff2d9",

    blue400: "#276EF1",
    blue300: "#5b91f5",
    blue200: "#a0bff8",
    blue100: "#d4e2fc",

    orange400: "#FF6937",
    orange300: "#fa9269",
    orange200: "#fabda5",
    orange100: "#ffe1d6",

    green400: "#05944F",
    green300: "#06C167",
    green200: "#66D19E",
    green100: "#ADDEC9",
    green50:  "#CDFEE9",

    primary: "#000000",
    // secondary: "#276EF1",
    //secondary: "#2334FF",
    //secondary: "#3544fb",
    secondary: "#3e4df9",
    gray900:  "#141414",
    gray800:  "#1F1F1F",
    gray700:  "#333333",
    gray600:  "#545454",
    gray500:  "#757575",
    gray400:  "#AFAFAF",
    //gray300:  "#CBCBCB",
    gray300:  "#dbdee1",
   // gray200:  "#E2E2E2",
     gray200: "#E7E9EB",
    // gray200:  "#6a748028",
    gray100:  "#EEEEEE",
    gray50:   "#F6F6F6",
   

    black: "#121212",
    title: "#292831",
    subtitle: "#605F67",

    hover: "#6a748028",
    background: "#ffffff",
    background_secondary: "#f2f3f5",
    background_tertiary: "#e6e8e9",
    border: "#eaeaea",
    ripple: "#F0F0F0",
    shape: "#f9f9f9",
    white: "#FFFFFF",
    gray: "#CFCFCF",

    blue: "#3D7199",
    blue_light: "#EBF6FF",

    error: "#E83F5B",
    error_title: "#ffffff",

    success: "#38B64A",
    success_background: "#EBF6F1",
    success_title: "#ffffff",

    facebook: "#1877f2",

   
    warning_title: "#ffffff",
}

export const layout = {
    window: {
        width,
        height
    },
    isSmallDevice: width < 900,
}

export const shadow = {
    "low": `0 1px 0 rgba(6,6,7,0.1),0 1.5px 0 rgba(6,6,7,0.025),0 2px 0 rgba(6,6,7,0.025)`,
    "medium": `0 4px 4px rgba(0,0,0,0.08)`,
    "high": `0 8px 16px rgba(0,0,0,0.16)`
}

const config = {
    "url": {
       gestorapi: "http://26.139.88.7:3010/", 
       api: "http://192.168.0.29:3000/", 
     //   api: "http://192.168.0.29:5000/", 
      // api: "https://portal-api.entregakii.com.br/", 
        paymentMethodsImagens: "https://files.entregakii.com.br/paymentMethods/",
        productsImagens: "https://files.entregakii.com.br/products/",
        garnishItemsImagens: "https://files.entregakii.com.br/garnishItems/",
        merchantsImagens: "https://files.entregakii.com.br/merchants/",
        adivertisementsImagens: "https://files.entregakii.com.br/advertisements/",
        campaignsImagens: "https://files.entregakii.com.br/campaigns/",
        promotionsImagens: "https://files.entregakii.com.br/promotions/",
        activityImagens: "https://files.entregakii.com.br/activities/"
    },
    layout,
    shadow,
    "font": {
        bold: "Whitney Bold",
        default: "Whitney",
    }
}

export const routes = [
    [
        // {
        //     title: "Início",
        //     route: "",
        //     exact: true,
        //     icon: "home",
        //     permissions: ["ADMIN","OWNER","STANDARD"]
        // },
        {
            title: "Avaliações",
            route: "avaliacoes",
            exact: true,
            icon: "star",
            permissions: ["ADMIN","OWNER"]
        },
        {
            title: "Financeiro",
            route: "financeiro",
            exact: true,
            icon: "money",
            permissions: ["ADMIN","OWNER"]
        },
        {
            title: "Pedidos",
            route: "pedidos",
            exact: true,
            icon: "order_outline",
            permissions: ["STANDARD","ADMIN","OWNER"]
        }
    ],


    [
        {
            title: "Cardápio",
            route: "categorias",
            exact: true,
            icon: "book",
            permissions: ["STANDARD","ADMIN","OWNER"]
        },
        {
            title: "Horários",
            route: "horarios",
            exact: true,
            icon: "stopwatch",
            permissions: ["STANDARD","ADMIN","OWNER"]
        },
        {
            title: "Áreas de entrega",
            route: "areas-de-entrega",
            exact: true,
            icon: "motocycle",
            permissions: ["STANDARD","ADMIN","OWNER"]
        },
        {
            title: "Formas de pagamento",
            route: "formas-de-pagamento",
            exact: true,
            icon: "wallet",
            permissions: ["STANDARD","ADMIN","OWNER"]
        },
        // {
        //     title: "Cupons de desconto",
        //     route: "cupons",
        //     exact: true,
        //     icon: "coupon",
        //     permissions: ["ADMIN","OWNER"]
        // },
        {
            title: "Promoções",
            route: "promocoes",
            exact: true,
            icon: "discount",
            permissions: ["ADMIN","OWNER"]
        },
        {
            title: "Serviços",
            route: "servicos",
            exact: true,
            icon: "wrench",
            permissions: ["ADMIN","OWNER"]
        }
    ],

    [
        {
            title: "Perfil",
            route: "perfil",
            exact: true,
            icon: "shop",
            permissions: ["ADMIN","OWNER"]
        },
        // {
        //     title: "Plano",
        //     route: "plano",
        //     exact: true,
        //     icon: "shop",
        //     permissions: ["OWNER"]
        // },
        {
            title: "Funcionários",
            route: "funcionarios",
            exact: true,
            icon: "user",
            permissions: ["OWNER"]
        },
        {
            title: "Desempenho",
            route: "desempenho",
            exact: true,
            icon: "performance",
            permissions: ["ADMIN","OWNER"]
        }
    ]
]

export default config