import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   

   @keyframes loadingSpin {
       0%{
            transform: rotate(0deg);

       }
       100%{
           transform: rotate(360deg);
  
       }
   }

   .loading{
       animation: loadingSpin 1s infinite;
      
       border: 2px solid transparent;
      

       border-radius: 12px;
   }
`;

const Loading = ({color = "#000000ff",size=24,type="container"}) => {
  return <Container style={{height: type === "container" ? "100%" : 24,width: type === "container" ? "100%" : 24}}>
      <div style={{
          borderBottom: `${size/8}px solid transparent`,
          borderLeft: `${size/8}px solid transparent`,
          borderRight: `${size/8}px solid transparent`,
          borderTop: `${size/8}px solid ${color}`,
          width: size,
          height:size,
          borderRadius: size/2
        }} className="loading"/>
  </Container>;
}

export default Loading;