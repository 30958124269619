import axios from 'axios';
import React,{ useEffect, useState } from 'react';
import styled from 'styled-components';
import useRedirect from '../functions/useRedirect'
import LOGO from '../assets/logo.png'

const RedirectPage = () => {

  console.log(window.location,window.location.pathname+window.location.search)

   const [data,setData] = useState<any>();


  const AppRedirect = useRedirect();
  var qs = window.location.pathname+window.location.search;


  const redirect = () => {
    AppRedirect.redirect({

      iosApp: 'entregakii://'+qs,

      iosAppStore: 'https://apps.apple.com/us/app/entregakii-app-de-delivery/id1594408952',

      // For this, your app need to have category filter: android.intent.category.BROWSABLE
      android: {
          'host': qs, // Host/path/querystring part in a custom scheme URL
          // 'action': '', // Equivalent to ACTION in INTENTs
          // 'category': '', // Equivalent to CATEGORY in INTENTs
          // 'component': '', // Equivalent to COMPONENT in INTENTs
          'scheme': 'entregakii', // Scheme part in a custom scheme URL
          'package': 'br.com.entregakii', // Package name in Play store
          'fallback': 'https://play.google.com/store/apps/details?id=br.com.entregakii'
      }

  });
  }

    const getData = async () => {

        let [,slug] = window.location.pathname.split("/")

        if(!slug.includes("-"))
            return;

        const res = await axios.get("https://api.entregakii.com.br/merchants/lite/"+slug)
        setData(res.data);
    }

    useEffect(() => {
        getData();
    },[])

    if(data)
      return (
        <Container>

        {/* <div className='content'> */}
          {!!data && <div className='picture' style={{backgroundImage: `url(https://files.entregakii.com.br/merchants/${data.picture})`}}/>}

          <div className="title">
            {data.name}
          </div>

          {!!data.description && <div className="subtitle">
              {data.description}
          </div> }

          <button onClick={redirect}>
            Abrir no app
          </button>

          <div className="subtitle" style={{color: "#888"}}>
            Clique e abra a loja no aplicativo.
          </div>

          {/* <div className="note">
              NOTE: In Chrome for Android, if the user writes the url manually, it assumes that he does <b>not</b> wish to be redirected outside of the browser. So you want to be redirected to this link or have clicked a link to this url.
          </div> */}
          {/* <a onClick={redirect}>Ir para o app</a> */}
          {/* </div> */}
        </Container>
      );
    else
      return (
        <Container>

        {/* <div className='content'> */}
           <div className='picture' style={{backgroundImage: `url(${LOGO})`}}/>

          <div className="title">
            Entregakii
          </div>

           <div className="subtitle">
              {"https://www.entregakii.com.br"+qs}
          </div>  

          <button onClick={redirect}>
            Abrir no app
          </button>

          <div className="subtitle" style={{color: "#888"}}>
            Clique e abra este link pelo aplicativo.
          </div>

          {/* <div className="note">
              NOTE: In Chrome for Android, if the user writes the url manually, it assumes that he does <b>not</b> wish to be redirected outside of the browser. So you want to be redirected to this link or have clicked a link to this url.
          </div> */}
          {/* <a onClick={redirect}>Ir para o app</a> */}
          {/* </div> */}
        </Container>
      );
}


export const Container = styled.div`

  padding: 50px;

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  

  .picture{
    width: 150px;
    height: 150px;
    background-color: #ddd;
    background-size: cover;
    border-radius: 75px;
  }

  .title{
    font-size: 32px;
    text-align: center;
    font-family: Whitney Bold;
    margin-top: 20px;
    max-width: 600px;
  }
  .subtitle{
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
    max-width: 600px;
    font-family: Whitney;
  }

  button {
 
    width: 100%;
    margin-top: 20px;
    height: 2.81rem;
    line-height: 2.8rem;
    border-radius: 7px;
    background-color: #2334FF;
    box-shadow: 0 4px 14px 0  #2334FF77;
    color: white;
    transition: background 0.2s ease, color 0.2s ease, box-shadow 0.2s ease;
    outline: none;
    cursor: pointer;
    border: none;
    max-width: 300px;
    font-size: inherit;
    font-family: Whitney Bold;
}
`;





export default RedirectPage;
